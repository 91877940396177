<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">
        <a-form-model-item prop="platform_type" label="适用平台">
          <a-select placeholder="适用平台" style="width:130px;"
                    v-model="searchForm.platform_type">
            <a-select-option v-for="(item, index) in platOpts"
                             :key="index"
                             :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        
        <a-form-model-item prop="name" label="官方名">
          <a-input placeholder="请输入官方名" v-model="searchForm.name" />
        </a-form-model-item>

        <a-form-model-item prop="name_interior" label="内部名">
          <a-input placeholder="请输入内部备注名" v-model="searchForm.name_interior" />
        </a-form-model-item>

        <a-form-model-item prop="effective_time" label="活动日期">
          <a-range-picker style="width:240px"
            v-model="searchForm.effective_time"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
          <a-button style="margin-left: 10px" @click="handlerReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div>
      <div class="flex justify-between p-3">
        <span class="text-lg font-bold">满额活动</span>
      </div>
      <div class="flex justify-between mr-2" style="margin-left:10px">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          <div slot="tabBarExtraContent">
            <a-button type="primary" @click="handlerAdd" html-type="submit">新建</a-button>
          </div>
        </a-tabs>
      </div>
    </div>

    <base-table
      ref="reduceTableRef"
      id="reduceTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :getListFunc="initData"
    >
      <template #platform="{record}">
        <span v-if="record.platform_xcx==2" class="ml-1 mr-1">小程序</span>
        <span v-if="record.platform_pc==2" class="ml-1 mr-1">网站</span>
      </template>
      <template #status="{text}">
        <a-tag class="mr-0" :color="text===0? 'red' : 'green' ">{{text===0 ? '停用' : '正常'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-1 mr-1" type="link" @click="handlerEdit(record)">编辑</a-button>
        <a-button class="p-0 ml-1 mr-1" type="link" @click="handlerCopy(record)">复制</a-button>
        <a-button class="p-0 ml-1 mr-1" type="link" @click="handlerStop(record)">{{record.status === 0? '恢复': '停用'}}</a-button>
      </template>
    </base-table>
  </div>
</template>

<script>
import pageData from "./columns"
import {
  getList,
  // getDetailById,
  // addActivity,
  // editActivity,
  updateStatus,
} from "@/api/activity/full-reduce.js"
export default {
  data() {
    return {
      ...pageData,
      platOpts: [
        { value: 0, name: "不限" },
        { value: 1, name: "小程序" },
        { value: 2, name: "网站" },
        { value: 3, name: "小程序+网站" },
      ],
      activeKey: 0,
      wrapperCol: { span: 16 },
      labelCol: { span: 6 },
      total: 0,
      searchForm: {
        platform_type: 0,
        name: "",
        effective_time: [],
        name_interior: "",
        event_time_start: "",
        event_time_end: "",
        date_type: 0,
        page: 1,
        page_count: 20,
      },
      tableData: [],
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "未开始" },
        { type_id: 2, type_name: "进行中" },
        { type_id: 3, type_name: "已结束" },
      ],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const params = this.searchForm
      if (this.searchForm.effective_time.length > 0) {
        params.event_time_start = this.searchForm.effective_time[0]
        params.event_time_end = this.searchForm.effective_time[1]
      }
      const { data, code } = await getList(this.searchForm)
      if (code == 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.date_type = e

      this.searchForm.page = 1
      
      this.initData()
    },

    handlerAdd() {
      this.$router.push({
        path: "/full-reduce-edit",
        query: { type: "add" },
      })
    },

    handlerEdit(row) {
      this.$router.push({
        path: "/full-reduce-edit",
        query: { type: "edit", id: row.id },
      })
    },
    handlerCopy(row) {
      this.$router.push({
        path: "/full-reduce-edit",
        query: { type: "copy", id: row.id },
      })
    },
    async handlerStop(e) {
      if (e.status == 0) {
        e.status = 1
      } else {
        e.status = 0
      }
      const { code } = await updateStatus({ id: e.id, status: e.status })
      if (code == 0) {
        this.$message.success("操作成功")
        this.initData(this.searchForm)
      }
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData(this.searchForm)
    },
    handlerReset() {
      this.searchForm.name = ""
      this.searchForm.name_interior = ""
      this.searchForm.event_time_start = ""
      this.searchForm.event_time_end = ""
      this.searchForm.date_type = 0
      this.searchForm.page = 1
      this.searchForm.page_count = 20
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
</style>