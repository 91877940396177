import { formatGoodsType } from "@/utils/type"

export const rowKey = "id";
export const columns = [
  {
    title: "官方名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "内部备注名",
    align: "center",
    dataIndex: "name_interior",
  },
  {
    title: "适用平台",
    dataIndex: "platform",
    width: 180,
    slots: { customRender: "platform" },
    align: "center",
  },
  {
    title: "活动开始",
    align: "center",
    dataIndex: "start_time",
  },
  {
    title: "活动结束",
    align: "center",
    dataIndex: "end_time",
  },
  {
    title: "活动状态",
    align: "center",
    width: 80,
    dataIndex: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 160,
    slots: {
      customRender: "operation",
    },
  },
];



export const goodsColumn = [
  // {
  //   title: "商品编码",
  //   dataIndex: "goods_number",
  //   align: "center",
  //   width: 200,
  // },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "goods_name",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "goods_type",
    width: 100,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 100,
    slots: {
      customRender: "operation",
    },
  },
]

export default {
  rowKey,
  columns,
};
