import ajax from '@/utils/ajax.js'

// 获取满额活动列表
export function getList(params) {
  return ajax.post('/AllEventOffers/getList',params)
}


// 获取满额活动详情
export function getDetailById(params) {
  return ajax.post('/AllEventOffers/detail',params)
}

/**
 * 新增活动
 * @param {*} params 
 * @returns 
 */
export function add(params) {
  return ajax.post('/AllEventOffers/add',params)
}

/**
 * 修改活动
 * @param {*} params 
 * @returns 
 */
export function edit(params) {
  return ajax.post('/AllEventOffers/update',params)
}

/**
 * 修改状态，停用/恢复 
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/AllEventOffers/upStatus',params)
}


/**
 * 添加赠品使用 
 * @param {*} params 
 * @returns 
 */
 export function getGiftGoodsList(params) {
  return ajax.post('/AllEventOffers/getGoodsListAddGiftUse',params)
}

/**
 * 添加优惠券赠品使用 
 * @param {*} params 
 * @returns 
 */
 export function getCouponList(params) {
  return ajax.post('/AllEventOffers/getCouponListAddGiftUse',params)
}


/**
 * 添加商品使用 
 * @param {*} params 
 * @returns 
 */
 export function getGoodsList(params) {
  return ajax.post('/AllEventOffers/getGoodsListAddGoodsUse',params)
}